// Colors

$black : #000;
$white: #FFF;
$nav-hover: #FC0;
$link-hover: #FFCD30;

$bands-color1: #820333;
$bands-color2: #F0433A;
$bands-color3: #D98014;
$bands-color4: #F2C335;
$bands-color5: #758757;
$bands-color6: #D7CD76;
$bands-color7: #5486BD;
$bands-color8: #76BAF2;
$bands-color9: #565656;
$bands-color10: #959595;

$logo-bg-color: #333;
$logo-color: #666;
$navbar-bg-color: #999;
$accent-color: #A6290D;
$hover-color: #FF7820;
$dots-hover: gray;
$subtle-grey: #eee;

$animation-duration: 0.7s;
$hover-duration: 0.4s;

$percentages: 100%, 95%, 90%, 85%, 80%, 75%, 70%, 65%, 60%, 55%, 50%, 45%, 40%, 35%, 30%, 25%, 20%, 15%, 10%, 5%, 0%;

$percentages: 100%, 90%, 80%, 70%, 60%, 50%, 40%, 30%, 20%, 10%, 0%;

$main-font: "Roboto Slab", sans-serif;
$header-font: "Raleway", sans-serif;

$h1-font-size: 1.5rem;
$h2-font-size: 1.3rem;
$logo-font-size: 2rem;
$tagline-font-size: 1.2rem;
$header-weight: 500;
$header-height: 120px;
$header-reduced-height: 100px;
$nav-font-size: 20px;
$reduced-nav-font-size: 16px;

$desktop-breakpoint: 1200px;
$midpoint-breakpoint: 992px;
$mobile-breakpoint: 768px;

$slidein-left-val: 3%;
