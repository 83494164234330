@import "variables";

.br {

    background: $white;
    color: black;
    font-family: $main-font;
    font-size: 17px;
    font-weight: 300;
    overflow-x: hidden;

    &.frozen {
         overflow: hidden;
    }

    .row > div {
        //border: 1px solid #000;
    }

    a {
        color: $accent-color;
        cursor: pointer;
        position: relative;

        &:after {
                content:'';
                display: block;
                background: $hover-color;
                height: 1px;
                width: 0;
                position: relative;
            }

        &:hover {
            color: $hover-color;
            transition: color .4s linear;
            text-decoration: none;
            
            &:after {
                width: 100%;
                transition: $hover-duration width ease-out;
            }

        }

        &.no-underline {
            
            &:after {
                display: none;
            }
        }
    }
    .content {
        position: relative;
        z-index: 10;
    }
    h1,
    h2,
    h3,
    h4 {
        color: black;
        margin-bottom: 20px;
        font-family: $main-font;
        font-weight: $header-weight;
    }
    h1 {
        font-size: $h1-font-size;
        
        &:before {
            content:'';
            width: 12px;
            height: 12px;
            display: block;
            background: $subtle-grey;
            position: absolute;
            top: 8px;
            left: -10px;
            transform: rotate(45deg);
        }

        &.active {

            &:before {
                transform: rotate(-315deg);
                transition: $animation-duration transform ease-out;
            }
        }
    }

    h2 {
        font-size: $h2-font-size;
        color: $accent-color;
    }

    p {
        font-weight: 300;
    }
    img {
        transition: opacity 1s linear;

        &:not(.bmw-mobile):not(.chlora-mobile) {
            background: white;
            padding: 5px;
            border: 2px solid $accent-color;
        }
        &.show {
            opacity: 1;
        }
    }
    a:not([href]):not([tabindex]) {
            color: $accent-color;
            

            &:hover {
                color: $hover-color;
                cursor: pointer;
            }
        }

    .container {
        @media (min-width: 1200px) {
            max-width: 1240px;
        }
    }

    header {
        background: white;
        border-bottom: 1px solid $accent-color;
        color: black;
        display: flex;
        font-family: $header-font;
        height: $header-height;
        justify-content: space-between;
        position: fixed;
        width: 100%;
        z-index: 12;
        
        /*&:after {
            content: '';
            display: block;
            position: absolute;
            top: 60px;
            background: $subtle-grey;
            height: 1px;
            width: 220px;
        }*/

        @media (max-width: $mobile-breakpoint) {
            background: $black;
            color: $white;
        }

        .nav {
            //border: 1px #fff solid;
            color: white;
            list-style-type: none;
            font-weight: 600;
            height: 40px;
            margin: 30px 0;
            padding: 10px 20px 0 0;
            text-transform: uppercase;
            position: relative;
            z-index: 2;

            @media (max-width: $mobile-breakpoint) {
                margin-top: 5px;
                padding: 0;
                width: 160px;
            }

            li {
                float: left;
                margin-left: 20px;
                cursor: pointer;
            }

            a {
                &:focus {
                    outline: none;
                }
            }
        }
        .medallion {
            width: 12px;
            height: 12px;
            display: block;
            background: $accent-color;
            position: relative;
            top: 114px;
            transform: rotate(45deg);
        }
        .logo-bg {
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-left: 200px solid $logo-bg-color;
            border-bottom: 50px solid transparent;
            position: absolute;
            z-index: 1;
            @media (max-width: $mobile-breakpoint) {
                display: none;
            }
        }
        .nav-bg {
            border-top: 50px solid transparent;
            border-left: 700px solid $navbar-bg-color;
            border-bottom: 150px solid transparent;
            position: absolute;
            transform: rotate(180deg);
            right: 0;
            top: -100px;
            z-index: 1;
        }

        .longarrow {
            position: absolute;
            fill: $navbar-bg-color;
            width: 700px;
            transform: rotate(0deg);
            top: 0;
            right: -50px;
        }

        /*&.transition1 {

            .longarrow {
                transition: $animation-duration transform ease-out, $animation-duration top ease-out, $animation-duration fill ease-out;
                transform: rotate(5deg);
                top: -70px;
                fill: orange;
            }
        }*/

        &.reduce {
            height: $header-reduced-height;
            transition: $animation-duration height ease-out;

            .logo {
                transform: scale(0.9);
                margin-top: 10px;
                transition: $animation-duration transform ease-out, $animation-duration margin-top ease-out;
            }

            .nav {
                font-size: $reduced-nav-font-size;
                transition: $animation-duration font-size ease-out;
            } 

            &:after {
                top: 50px;
                transition: $animation-duration top ease-out;
            }

            .medallion {
                transform: rotate(-315deg);
                transition: $animation-duration transform ease-out, $animation-duration top ease-out;
                top: 94px;
            }
        }

        &.increase {
            height: $header-height;
            transition: $animation-duration height ease-out;

            .logo {
                transform: scale(1);
                margin-top: 20px;
                transition: $animation-duration transform ease-out, $animation-duration margin-top ease-out;
            }
            .nav {
                transition: $animation-duration font-size ease-out;
            }
             &:after {
                transition: $animation-duration top ease-out;
            }
            .medallion {
                transition: $animation-duration transform ease-out, $animation-duration top ease-out;
            }
        }

    }

    // Section Backgrounds
    .bg {
        background-size: cover;
        height: 100vh;
        opacity: 0;
        position: fixed;
        transition: opacity 1s linear;
        width: 100%;
        &.show {
            opacity: 1;
        }
    }
    .hero-bg {
        background: url('./../img/forest.jpg') #666;
        opacity: 1;
        z-index: 1;
    }
    .graphic-bg {
        background-size: cover;
        background: url('./../img/coney-island2.jpg') no-repeat;
        z-index: 2;
    }
    .development-bg {
        background: #666;
        display: flex;
        justify-content: center;
        z-index: 3;
        div {
            align-self: center;
            border-radius: 0px;
            display: block;
            height: 0px;
            width: 0px;
            position: absolute;
            @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                    background: mix($bands-color1, $bands-color2, nth($percentages, $i));
                }
            }
        }
        &.expand {
            div {
                transition: width 1s;
                height: 100%;
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        width: nth($percentages, $i);
                        transition: all $i * 0.25s;
                        z-index: $i;
                    }
                }
            }
        }
        &.blue {
            transition: background 1s;
            div {
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        background: mix($bands-color7, $bands-color8, nth($percentages, $i));
                        transform: rotate(0deg);
                        transition: all $i * 0.25s;
                    }
                }
            }
        }
        &.gold {
            transition: background 1s;
            div {
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        background: mix($bands-color3, $bands-color4, nth($percentages, $i));
                        transform: rotate(0deg);
                        transition: all $i * 0.25s;
                    }
                }
            }
        }
        &.grey {
            transition: background 1s;
            div {
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        background: mix($bands-color9, $bands-color10, nth($percentages, $i));
                        transform: rotate(0deg);
                        transition: all $i * 0.25s;
                    }
                }
            }
        }
        &.rotate {
            transition: transform 1s;
            div {
                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        background: mix($bands-color5, $bands-color6, nth($percentages, $i));
                        transform: rotate(90deg) scaleY(2);
                        transition: all $i * 0.25s;
                    }
                }
            }
        }
    }
    .image-row {
        margin-top: 100px;
        //transform: skew(-5deg) rotate(-5deg);
        
        .row {
            &.first {
                >div {
                    //border: 1px solid red;
                    height: 270px;

                    &:first-child {
                        background: url('./../img/kbs/ludens.jpg');
                        background-size: 125%;
                        background-position: center;
                    }
                    &:nth-child(2) {
                        background: url('./../img/graphic/biscuits.png') no-repeat;
                        background-size: 130%;
                        background-position: center;
                    }
                    &:nth-child(3) {
                        background: url('./../img/kbs/chloraseptic.png');
                        background-size: 100%;
                    }
                }
            }

            &.second {
                >div {
                    //border: 1px solid red;
                    height: 300px;
                    
                    &:first-child {
                        background: url('./../img/kbs/mk-screenshot.jpg');
                        background-size: cover;
                        background-position: center;
                        
                    }
                    &:nth-child(2) {
                        background: url('./../img/docero/docero-screen.jpg');
                        background-size: cover;
                        background-position: center;
                    }
                    &:nth-child(3) {
                        background: url('./../img/graphic/aphrodesia.jpg');
                        background-size: 100%;
                        background-position: center;
                    }
                }

            }
        }
    }
    .contact-bg {
        background: url('./../img/contact-bg.jpg') no-repeat;
        z-index: 3;
    }
    .diagonal-box {
        background: url('../img/trees.jpg');
        height: 2000px;
        mix-blend-mode: overlay;
        opacity: 0;
        position: absolute;
        transform: scale(4);
        width: 50%;
        z-index: 4;
        &.animate {
            left: -20%;
            opacity: 1;
            transition: transform 1s ease-out, opacity 1s ease-out;
            transform: scale(1);
        }
        &.hide {
            opacity: 0;
        }
        &.show {
            opacity: 1;
        }
    }
    section {
        height: 900px;

        &:after {
            content: '';
            display: block;
            background: $accent-color;
            height: 1px;
            width: 100%;
        }
    }
    .animations {
        bottom: 50px;
        cursor: pointer;
        list-style-type: none;
        padding: 5px 20px;
        position: fixed;
        right: 50px;
        z-index: 2;
    }
    .logo {
        font-size: $logo-font-size;
        font-weight: 600;
        margin: 20px 0 0 20px;
        position: relative;
        z-index: 2;
        text-transform: uppercase;

        span {
            font-size: $tagline-font-size;
            font-weight: 200;
            margin-top: -10px;
            display: block;
            text-transform: capitalize;

            @media (max-width: $midpoint-breakpoint) {
                font-size: 20px;
                line-height: 20px;
                font-weight: 800;
            }
        }
    }
    .hello {
        padding-top: 50px;
        color: black;
        vertical-align: bottom;
        padding-top: 200px;

        ul {
            border: 1px solid #fff;
            list-style-type: none;
            max-width: 60%;
            margin-top: 140px;
            padding-left: 20px;
            &.border {
                border: 1px solid #fff;
                transition: opacity 1s linear;
            }
            @media (min-width: $mobile-breakpoint) {
                margin-top: 290px;
            }
            li {
                display: inline;
                opacity: 0;
                transition: opacity 1s linear;
                &.active {
                    opacity: 1;
                }
                &:nth-child(2) {
                    font-size: 1.5em;
                    @media (min-width: $midpoint-breakpoint) {
                        line-height: 20px;
                    }
                }
                &:nth-child(4) {
                    font-size: 1.4em;
                    @media (min-width: $midpoint-breakpoint) {
                        line-height: 20px;
                    }
                }
            }
        }
        .bmw-mobile,
        .chlora-mobile {
            position: absolute;
            width: 450px;
        }
        .bmw-mobile {
            left: 0;
            z-index: 2;
        }
        .chlora-mobile {
            right: 0;
            top: 80px;
            z-index: 1;
        }
    }

    .panel {
            transition: opacity 1s linear;
            left: -2500px;
            position: absolute;
            width: 95%;

            .row {
                position: absolute;

                &:nth-child(n+2) {
                    display: none;
                }
            }

            .col {
                min-height: 450px;
            }

            hr {
                display: block;
            }

            .case-nav {
                display: flex;
                justify-content: space-between;
            }

            .subpanel {
                display: none;

                &:first-of-type {
                    display: block;
                }
            }

            .subpanel-nav {
                display: flex;
                justify-content: center;
                border-top: 1px dotted $accent-color;
                border-bottom: 1px dotted $accent-color;
                padding: 10px 0;
                position: absolute;
                bottom: 30px;
                margin-right: 10px;
                width: 95%;

                .dot {
                        display: block;
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                        border: 3px solid $accent-color;
                        margin: 0 5px;
                        border-radius: 20px;

                        &:hover {
                            background: $accent-color;
                        }
                    }

            }
            
            &.moveout {
                animation: moveout 1s forwards;
                animation-iteration-count: 1;
            }
            &.movein {
                animation: movein 0.5s forwards;
                animation-iteration-count: 1;
            }

            &.opener,
            &.prevwork {
                position: absolute;
                z-index: 2;
                left: 30px;
                width: 90%;
            }
        }

    .kbs {

        .sites {
            display: flex;
            justify-content: space-between;
        }
    }

    .ux {
        height: 1000px;

        @keyframes moveout {
          from {opacity: 1; left: $slidein-left-val;}
          to {opacity: 0; left: -2500px;}
        }

        @keyframes movein {
            from {opacity: 0; left: -2500px;}
            to {opacity: 1; left: $slidein-left-val;}
          
        }

        .ux-text {
            padding-bottom: 30px;
        }

        padding-top: 200px;

        .container {
            position: relative;
        }

        .puma {
            position: relative;
            width: 100%;

             @media (min-width: $mobile-breakpoint) {
                position: absolute;
            }

            &.show {
                opacity: 1;
            }
        }

        .puma-ux {
            border: 1px solid #f00;
            width: 100%;
        }

        .puma-iframe {
            width: 760px;
            height: 800px;
        }

        .puma-wireframe {
            height: 1000px;
        }


        &:after {
            display: none;
        }
    }
    .graphic {
        background-size: cover;
        overflow: hidden;
        position: relative;

        h1 {
            filter: blur(10px);
            margin-left: -100%;
        }
        .overlay {
            background-blend-mode: color-dodge;
            background: lightblue;
            display: block;
            height: 100%;
            opacity: .5;
            position: absolute;
            top: 0;
            width: 100%;
        }
        .corkboard {
            position: absolute;
            z-index: 2;
        }
        img {
            margin: 7px 3px;
            &:hover {
                opacity: 1;
                outline: 3px solid #fff;
                transition: .7s opacity;
                z-index: 2;
            }
        }
        .diagonal-box {
            background: url('../img/diagonal.png');
            height: 2500px;
            mix-blend-mode: color-burn;
            opacity: 0;
            position: absolute;
            transform: scale(4);
            width: 200%;
        }
        &.animate {
            h1 {
                filter: blur(0px);
                margin-left: 0;
                transition: filter 1s ease-in, margin-left .7s ease-in;
            }
        }
        @media (max-width: $mobile-breakpoint) {
            img {
                width: 80px;
            }
        }
    } // end of graphic rule
    .development {
        color: black;
        overflow: hidden;
        position: relative;
        padding-top: 180px;
        min-height: 300px;

        @media (max-width: $midpoint-breakpoint) {
            height: 1000px;
        }

        &:after {
            margin-top: 480px;
        }

        .companies {
            width: 100%;
            position: relative;
            
            @media (min-width: $midpoint-breakpoint) {
                position: absolute;
                //width: 90%;
            }
        }
        
        h1 {
            color: black;
            margin-top: 0;

            @media (min-width: $desktop-breakpoint) {
                margin-top: 75px;
            }

            &:before {
                display: none;
            }
        }

        h4 {
            cursor: pointer;
            margin-bottom: 5px;

            a {
                cursor: pointer;
            }
        }

        .project-images {
            width: 100%;

            img {
                margin-top: 10px;
                //padding: 0;
                position: absolute;
                display: none;

                &:first-child {
                    display: block;
                }

                @media (max-width: $midpoint-breakpoint) {
                    width: 95%;
                }
            }

            .amplify-vamp {
                top: 80px;
            }
        }

        .case-nav {
            position: absolute;
            bottom: 0;
            width: 95%;
        }

    }

    /* End Development Section */

    .motion {
        .vidbox {
            margin-top: 30px;
            video {
                width: 100%;
            }
        }
        .programs {
            list-style-type: none;
            margin-top: 360px;
            li {
                clear: both;
                margin-top: 20px;
                position: relative;
                min-height: 120px;
            }
            h4 {
                font-family: "Raleway", sans-serif;
                font-weight: 600;
                text-transform: none;
                font-weight: bold;
                margin-bottom: 10px;
            }
            img {
                float: left;
                width: 80px;
                margin-right: 20px;
                overflow: hidden;
            }
            p {
                overflow: hidden;
            }
        }
    }
    .contact {
        padding-top: 200px;

        p {
            font-size: 1.5em;
            a {
                color: green;
            }
        }
    }
    //Styles for the development page
    .develop-page {
        background: darkgrey;
        height: 3000px;
    }

    @import "ux";
}